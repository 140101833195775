import generateRow from "../../../features/Squares/services/RowGeneratorService";

const row = generateRow(0);

const square1 = row[0];
square1.imageName = 'r1s1.png';
square1.unitsRendered = 25;
square1.map = [
  {coords: {x1: 480, y1: 480, x2: 530, y2: 530}, href: 'https://9gag.com', alt: 'For 9gag!! Let\'s get trolling!'}
];

export default row;
