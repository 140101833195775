import React, {useEffect, useRef, useState} from 'react';
import PixelBoardTooltip from "../PixelBoardTooltip";
import PixelBoardMap from "../PixelBoardSquare";
import {isBrowser, isMobile} from "react-device-detect";

export default function PixelBoard({squares, squarePosition}) {
  const COMPONENT_BREADTH = 1000;
  let currentSquareId = (squarePosition.y + 1) + '-' + (squarePosition.x + 1);
  let currentSquare = squares[squarePosition.y][squarePosition.x];

  let component = useRef(null);
  let [linkData, setLinkData] = useState({name: '', position: {'x': 0, 'y': 0}, href: ''});
  let [showTooltip = false, setShowTooltip] = useState();
  let [isTooltipPositionFixed, setIsTooltipPositionFixed] = useState(false);

  useEffect(() => {
    let currentComponent = component.current;
    currentComponent.scrollLeft = (COMPONENT_BREADTH - currentComponent.offsetWidth) / 2;
  }, []);

  function onMouseOver(event) {
    if (!isTooltipPositionFixed) {
      setLinkData((prev) => ({
        ...prev,
        name: event.target.alt ? event.target.alt : 'Available pixels',
        href: event.target.href ?? null
      }));
    }
  }

  function onMouseMove(event) {
    setShowTooltip(true);

    if (!isTooltipPositionFixed) {
      setLinkData((prev) => ({...prev, position: {'x': event.pageX, 'y': event.pageY}}));
    }
  }

  function onMouseLeave() {
    if (!isTooltipPositionFixed) {
      setShowTooltip(false);
    }
  }

  function handlePixelUnitClick(event) {
    event.preventDefault();
    manageFixedPositionOnTooltip(true);

    setLinkData((prev) => ({
      ...prev,
      name: event.target.alt ? event.target.alt : 'Available pixels',
      position: {'x': event.pageX, 'y': event.pageY},
      href: event.target.href ?? null
    }));
  }

  function manageFixedPositionOnTooltip(setToFixed) {
    if (isTooltipPositionFixed) {
      setShowTooltip(false);
    } else {
      setShowTooltip(true);
    }

    setIsTooltipPositionFixed(setToFixed);
  }

  return (
    <React.Fragment>
      <div ref={component} className={'col pt-2 animate__animated animate__fadeIn' + ' ' + (isMobile ? 'px-3' : '')}
        style={{overflowY: 'auto'}}>
        <div className="pixel-board user-select-none" {...(isBrowser && {
          onMouseMove: onMouseMove,
          onMouseOver: onMouseOver,
          onMouseLeave: onMouseLeave
        })}>
          {showTooltip &&
            <PixelBoardTooltip
              linkName={linkData.name}
              linkPosition={linkData.position}
              linkHref={linkData.href}
              manageFixedPositionOnTooltip={manageFixedPositionOnTooltip}
              isTooltipPositionFixed={isTooltipPositionFixed}
            />
          }

          <PixelBoardMap square={currentSquare.map} squareId={currentSquareId}
            handlePixelUnitClick={handlePixelUnitClick} />

          <img
            src={
              currentSquare.imageName ?
                '/img/homepage/' + process.env.REACT_APP_ENVIRONMENT + '/' + currentSquare.imageName :
                'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNgYAAAAAMAASsJTYQAAAAASUVORK5CYII='
            }
            useMap={"#pixel-board__map"} alt="Pixel area image" width={COMPONENT_BREADTH}
            height={COMPONENT_BREADTH}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

